import {ModalServiceStandAlone} from "../../elements/generic-modal/modal-service-standalone.service";
import {Resource} from "../domain/resource";
import {EditorService} from "../editor.service";
import {Injectable} from "@angular/core";
import {AccessLevel, ResourceAccessService} from "./resource-access.service";
import {NavigableElement} from "../domain/navigable-element";
import {AccessControlComponent} from "./access-control.component";

@Injectable({
  providedIn: 'root'
})
export class ResourceAccessInteractionService extends EditorService<AccessLevel, AccessControlComponent> {

  protected constructor(
    modalService: ModalServiceStandAlone,
    private resourceAccessService: ResourceAccessService,
    // component: new (...args: any[]) => AccessControlComponent,
    // dialogClasses: string
  ) {
    super(modalService, AccessControlComponent, 'modal-dialog-centered')
  }

  public setAccessLevel(elementResource: Resource<NavigableElement>, doWithSubmitResponse: (response: void) => void) {
    this
      .resourceAccessService
      .getAccessLevel(elementResource)
      // this is bad! However, will be improved, once the open function returns an observable instead of consuming a callback
      .subscribe(accessSetupData => this.openModal(
        'Set access level of ' + elementResource.data.title,
        accessSetupData?.accessLevel ?? null,
        accessLevel => this.resourceAccessService
          .setAccessLevel(elementResource, accessLevel)
          .subscribe(doWithSubmitResponse)
      ))
  }

  // public editAccess(existing: Resource<T>, consumer: (response: Resource<T>) => void) {
  //   this.open(
  //     'Edit ' + this.resourceName,
  //     existing?.data,
  //     t => this
  //       .resourceService.save({
  //         assignmentId: existing.assignmentId,
  //         creationTimestamp: existing.creationTimestamp,
  //         id: existing.id,
  //         data: t
  //       })
  //       .subscribe(consumer)
  //   )
  // }
}
