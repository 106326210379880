import {Component, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";
import {RouterLink} from "@angular/router";
import {CommunityProfile} from "../../domain/community-profile";
import {RootResource} from "../../domain/resource";
import {CommunityRoutes} from "../../community.routes";
import {ProfileService} from "../profile.service";
import {tap} from "rxjs";
import {ProfileInteractionService} from "../profile-interaction.service";
import {HasWritePermissionDirective} from "../../access-control/has-write-permission.directive";

@Component({
  selector: 'app-discover',
  standalone: true,
    imports: [CommonModule, RouterLink, HasWritePermissionDirective],
  templateUrl: './discover.component.html',
  styleUrl: './discover.component.scss'
})
export class DiscoverComponent implements OnInit {

  communities!: RootResource<CommunityProfile>[];

  constructor(private profileService: ProfileService, private profileInteractionService: ProfileInteractionService) {
  }

  ngOnInit(): void {
    // console.log('init')
    this.profileService
      .getCommunities()
      .subscribe(
        communities => this.communities = communities
      )
  }

  create() {
    this.profileInteractionService.create(r => this.ngOnInit());
  }

  protected readonly AppRoutes = CommunityRoutes;
}
