import {Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ElementData} from "../../../generic-element/elementData";
import {FormsModule} from "@angular/forms";
import {Post} from "../../domain/post";
import {BehaviorSubject, distinctUntilChanged, Observable, of, switchMap} from "rxjs";
import {FullDelegate} from "../../domain/instant";
import {FileUploadService} from '../file-upload.service';
import {
  ShowScrollBarOnScrollDirective
} from '../../../elements/show-scroll-bar-on-scroll/show-scroll-bar-on-scroll.directive';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {MarkdownTag} from './markdown-tag';
import {YoutubeUrlFormComponent} from './youtube-url-form/youtube-url-form.component';
import {NotificationService} from '../../../services/notification.service';
import {ALLOWED_YOUTUBE_URLS, YoutubeUrlService} from './youtube-url.service';

@Component({
  selector: 'app-post-editor',
  standalone: true,
  imports: [
    FormsModule,
    ShowScrollBarOnScrollDirective,
    YoutubeUrlFormComponent
  ],
  templateUrl: './post-editor.component.html',
  styleUrl: './post-editor.component.scss'
})
export class PostEditorComponent implements OnInit, OnDestroy {
  private readonly youtubeUrlService = inject(YoutubeUrlService);
  private originalMarkdownContent = '';
  private hasChanges$ = new BehaviorSubject(false);

  @Input() post!: Post;
  @Input() accessDelegate: () => Observable<FullDelegate | null> = () => of(null);
  @Output() hasChanges = new EventEmitter<boolean>();

  markdownContent = '';
  showYouTubeUrlForm = false;

  constructor(
    private fileUploadService: FileUploadService,
    private notificationService: NotificationService
  ) {
    this.hasChanges$
      .pipe(
        distinctUntilChanged(),
        takeUntilDestroyed()
      )
      .subscribe(hasChanges => this.hasChanges.emit(hasChanges));
  }

  ngOnInit(): void {
    if (this.post && this.post.elements.length > 0) {
      this.markdownContent = this.generateMarkdownFromElements(this.post.elements);
      this.originalMarkdownContent = this.markdownContent;
    }
  }

  ngOnDestroy() {
    this.hasChanges$.complete();
  }

  onChange() {
    this.hasChanges$.next(this.originalMarkdownContent !== this.markdownContent);
  }

  generatePost(): Post {
    return {elements: this.generateElementsFromMarkdown(this.markdownContent)};
  }

  private generateElementsFromMarkdown(markdown: string): ElementData[] {
    const lines = markdown.split('\n');
    const elements: ElementData[] = [];
    let currentMarkdown = '';

    lines.forEach(line => {
      const videoMatch = line.match(new RegExp(`\\[${MarkdownTag.video}\\]: # \\((.+)\\)`));
      const audioMatch = line.match(new RegExp(`\\[${MarkdownTag.audio}\\]: # \\((.+)\\)`));
      const imageMatch = line.match(new RegExp(`\\[${MarkdownTag.image}\\]: # \\((.+)\\)`));
      const downloadButtonMatch = line.match(new RegExp(`\\[${MarkdownTag.downloadButton}\\]: # \\((.+)\\)`));
      const youTubeVideoMatch = line.match(new RegExp(`\\[${MarkdownTag.youTubeVideo}\\]: # \\((.+)\\)`));

      if (videoMatch || audioMatch || imageMatch || downloadButtonMatch || youTubeVideoMatch) {
        if (currentMarkdown) {
          elements.push({kind: MarkdownTag.markdown, content: currentMarkdown});
          currentMarkdown = '';
        }
        if (videoMatch) {
          elements.push({kind: MarkdownTag.video, content: videoMatch[1]});
        }
        if (audioMatch) {
          elements.push({kind: MarkdownTag.audio, content: audioMatch[1]});
        }
        if (imageMatch) {
          elements.push({kind: MarkdownTag.image, content: imageMatch[1]});
        }
        if (downloadButtonMatch) {
          elements.push({kind: MarkdownTag.downloadButton, content: downloadButtonMatch[1]});
        }
        if (youTubeVideoMatch) {
          elements.push({kind: MarkdownTag.youTubeVideo, content: youTubeVideoMatch[1]});
        }
      } else {
        currentMarkdown += (currentMarkdown ? '\n' : '') + line;
      }
    });

    if (currentMarkdown) {
      elements.push({kind: MarkdownTag.markdown, content: currentMarkdown});
    }

    return elements;
  }

  generateMarkdownFromElements(elements: ElementData[]): string {
    let markdown = '';
    elements.forEach(element => {
      if (element.kind === MarkdownTag.video) {
        markdown += `[${MarkdownTag.video}]: # (${element.content})\n`;
      } else if (element.kind === MarkdownTag.audio) {
        markdown += `[${MarkdownTag.audio}]: # (${element.content})\n`;
      } else if (element.kind === MarkdownTag.image) {
        markdown += `[${MarkdownTag.image}]: # (${element.content})\n`;
      } else if (element.kind === MarkdownTag.downloadButton) {
        markdown += `[${MarkdownTag.downloadButton}]: # (${element.content})\n`;
      } else if (element.kind === MarkdownTag.youTubeVideo) {
        markdown += `[${MarkdownTag.youTubeVideo}]: # (${element.content})\n`;
      } else if (element.kind === MarkdownTag.markdown) {
        markdown += `${element.content}\n`;
      }
    });
    return markdown.trim();
  }

  addVideo(): void {
    this.openFilePickerDialog('video/*', `[${MarkdownTag.video}]`);
  }

  addAudio(): void {
    this.openFilePickerDialog('audio/*', `[${MarkdownTag.audio}]`);
  }

  addImage(): void {
    this.openFilePickerDialog('image/*', `[${MarkdownTag.image}]`);
  }

  addDownloadButton(): void {
    this.openFilePickerDialog('application/pdf', `[${MarkdownTag.downloadButton}]`);
  }

  addYouTubeVideo(): void {
    this.showYouTubeUrlForm = true;
  }

  onYouTubeUrlAdded(url: string) {
    const youTubeVideoLink = this.youtubeUrlService.getYouTubeEmbedUrl(url);
    const fileLink = `[${MarkdownTag.youTubeVideo}]: # (${youTubeVideoLink})`;
    this.insertLineAtCursor(fileLink);
    this.showYouTubeUrlForm = false;
  }

  onYouTubeUrlFormClosed() {
    this.showYouTubeUrlForm = false;
  }

  private openFilePickerDialog(inputAcceptAttribute: string, customMarkdownTag: string) {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = inputAcceptAttribute;
    input.onchange = (event: any) => {
      if (event.target.files && event.target.files.length > 0) {
        const selectedFile = event.target.files[0];
        this.uploadFile(selectedFile, customMarkdownTag);
      }
    };
    input.click();
  }

  private uploadFile(file: File, customMarkdownTag: string): void {
    this.accessDelegate()
      .pipe(
        switchMap(delegate =>
          (delegate ? this.fileUploadService.uploadFile(file, delegate) : this.fileUploadService.uploadFile(file))
            .then((response) => {
              const fileLink = `${customMarkdownTag}: # (${response.url})`;
              this.insertLineAtCursor(fileLink);
            })
        )
      )
      .subscribe();
  }

  private insertLineAtCursor(text: string): void {
    this.insertAtCursor('\n' + text + '\n');
  }

  private insertAtCursor(text: string): void {
    const textarea: HTMLTextAreaElement | null = document.querySelector('#content');
    if (textarea) {
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      this.markdownContent = this.markdownContent.slice(0, start) + text + this.markdownContent.slice(end);
      textarea.selectionStart = textarea.selectionEnd = start + text.length;
      textarea.focus();
      this.onChange();
    }
  }
}
