import {Observable} from 'rxjs';
import {CommunityProfile} from '../../community/domain/community-profile';
import {ResourceId, RootResource} from '../../community/domain/resource';
import {ContentItemWithCommunity} from "../../secure/domain/content-item-with-community";

export abstract class UserContentApiService {
  abstract getFollowedCommunities(): Observable<RootResource<CommunityProfile>[]>;
  abstract getFollowedContent(): Observable<ContentItemWithCommunity[]>;
  abstract getCommunitiesWithCreatorRights(): Observable<RootResource<CommunityProfile>[]>;
  abstract setUserOpenedFeed(resource: ResourceId): Observable<void>;
  abstract setUserOpenedCollection(resource: ResourceId): Observable<void>;
}
