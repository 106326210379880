import {NavigableElement} from "./navigable-element";
import {Post, Posts} from "./post";
import {Resource, ResourceId} from "./resource";

export interface ContentElement extends NavigableElement {
  content: Post;
}

export class ContentElements {
  static empty(): ContentElement {
    return {
      content: Posts.empty(),
      title: ''
    }
  }

  // static initial(): ContentElement {
  //   return {
  //     introduction: undefined,
  //     title: ''
  //   }
  // }
}
