import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ModalExchange} from "../../../elements/generic-modal/modal-exchange";
import {EditorComponent} from "../../editor-component";
import {Collection, Collections} from "../../domain/collection";
import {PostEditorComponent} from "../../post/editor/post-editor.component";
import {ResourceAccessService} from "../../access-control/resource-access.service";
import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'app-collection-editor',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    PostEditorComponent
  ],
  templateUrl: './collection-editor.component.html',
  styleUrl: './collection-editor.component.scss'
})
export class CollectionEditorComponent implements EditorComponent<Collection>, OnInit {
  private postEditorHasChanges: boolean = false;

  @Input() data!: ModalExchange<Collection, Collection>;
  @ViewChild('postEditor') postEditor!: PostEditorComponent;

  collection!: Collection;

  constructor(
    protected resourceAccessService: ResourceAccessService,
    private notificationService: NotificationService
  ) {
  }

  ngOnInit(): void {
    if (this.data && this.data.input) {
      this.collection = Object.assign({}, this.data.input);
    } else {
      this.collection = Collections.empty()
    }
  }

  submit() {
    this.data.callback({
      title: this.collection.title,
      content: this.postEditor.generatePost(),
      kind: this.collection.kind || 'unspecified',
      chapters: this.collection.chapters
    });
  }

  onPostEditorHasChanges(hasChanges: boolean) {
    this.postEditorHasChanges = hasChanges
  }

  beforeCancel(): boolean {
    if (this.hasChanges()) {
      return this.notificationService.showConfirmation();
    }
    return true;
  }

  private hasChanges(): boolean {
    return this.postEditorHasChanges || this.collection.title !== (this.data?.input?.title || '');
  }
}
