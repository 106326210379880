import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {CommunityProfile} from "../domain/community-profile";
import {COMMUNITY_CREATION_TIMESTAMP, COMMUNITY_UUID, CREATION_TIMESTAMP, UUID} from "../community.routes";
import {ResourceId, RootResource, RootResourceId} from "../domain/resource";
import {ProfileService} from "../profile/profile.service";
import {ReplaySubject, Subject, Subscription, switchMap, tap} from "rxjs";
import {ResourceAccessService} from "../access-control/resource-access.service";

@Component({
  standalone: true,
  template: ``
})
export abstract class CommunityBaseComponent implements OnInit, OnDestroy {

  @HostBinding('class') class = 'd-flex w-100';

  // todo: here is quite some chaos as this data is partly also provided in ProfileService. Should be cleaned.
  community!: RootResource<CommunityProfile>;
  communitySubject: Subject<RootResource<CommunityProfile>> = new ReplaySubject<RootResource<CommunityProfile>>(1);
  contentResourceId!: ResourceId;

  communitySubscription!: Subscription;
  // mayWrite!: boolean;

  communityId!: RootResourceId;

  protected constructor(
    protected route: ActivatedRoute,
    protected profileService: ProfileService,
    protected accessService: ResourceAccessService
  ) {
  }

  ngOnInit(): void {
    const communityCreationTimestamp = Number(this.route.snapshot.paramMap.get(COMMUNITY_CREATION_TIMESTAMP) ?? '0');
    const communityUuid = this.route.snapshot.paramMap.get(COMMUNITY_UUID);
    const creationTimestamp = Number(this.route.snapshot.paramMap.get(CREATION_TIMESTAMP) ?? '0');
    const uuid = this.route.snapshot.paramMap.get(UUID);

    if ((communityCreationTimestamp || communityCreationTimestamp === 0) && communityUuid) {
      const communityId: RootResourceId = {creationTimestamp: communityCreationTimestamp, uuid: communityUuid}
      this.communityId = communityId;

      this.accessService.setCommunityDelegate(communityId)

      if (creationTimestamp != undefined && uuid) {
        this.contentResourceId = {assignmentId: communityUuid, creationTimestamp: creationTimestamp, uuid: uuid}
        this.accessService.setContentDelegate(this.contentResourceId);
      } else {
        // todo: this is a hack to make community profile work, should be rethought entirely
        this.accessService.setContentDelegate(communityId);
      }

      this.loadCommunity();
    } else {
      alert('Error: Community identifiers are required!');
    }
  }

  loadCommunity() {
    if (this.communitySubscription)
      this.communitySubscription.unsubscribe();

    this.communitySubscription = this.profileService
      .selectCommunityProfile(this.communityId.creationTimestamp, this.communityId.uuid)
      .pipe(
        tap(community => this.communitySubject.next(community)),
        tap(community => this.community = community),
      )
      .subscribe();
  }

  withCommunity() {
    return this.communitySubject.asObservable();
  }

  ngOnDestroy(): void {
    if (this.communitySubscription)
      this.communitySubscription.unsubscribe();
  }
}
