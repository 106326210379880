import {
  AfterViewInit,
  Component,
  ElementRef,
  inject,
  OnDestroy,
  OnInit, Signal,
  viewChild,
  ViewEncapsulation
} from '@angular/core';
import { EditorComponent } from '../../editor-component';
import { ModalExchange } from '../../../elements/generic-modal/modal-exchange';
import { CommunityProfile } from '../../domain/community-profile';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AsyncPipe, NgForOf, NgOptimizedImage } from '@angular/common';
import { PostEditorComponent } from '../../post/editor/post-editor.component';
import { SubscriptionPlan } from '../../../api/user/domain/subscription-plan';
import { Resource, ResourceId, RootResource } from '../../domain/resource';
import flatpickr from "flatpickr";
import { FeedService } from '../../feed/feed.service';
import { CollectionService } from '../../collection/collection.service';
import { ContentType } from '../../../api/user/domain/content-type';
import { combineLatest, map, Observable } from 'rxjs';
import { ContentElement } from '../../domain/content-element';
import { Feed } from '../../domain/feed';
import { Collection } from '../../domain/collection';
import { PostViewComponent } from '../../post/view/post-view.component';
import Instance = flatpickr.Instance;
import { UserStateApiService } from '../../../api/user/user-state-api.service';
import { SubscriptionRequest } from '../../../api/user/domain/subscription-request';
import { ContentPurchase } from '../../../api/user/domain/content-purchase';
import { ValidatorUtil } from '../../../utils/validator-util';
import { UserApiService } from '../../../api/user/user-api.service';
import { InviteUser } from '../../../api/user/domain/invite-user';

interface SelectableContentElement extends Resource<ContentElement> {
  type: ContentType,
  isSelected: boolean
}

@Component({
  selector: 'app-manage-user-access-editor',
  standalone: true,
  imports: [
    FormsModule,
    NgOptimizedImage,
    PostEditorComponent,
    ReactiveFormsModule,
    NgForOf,
    AsyncPipe,
    PostViewComponent
  ],
  templateUrl: './manage-user-access-editor.component.html',
  styleUrl: './manage-user-access-editor.component.scss'
})
export class ManageUserAccessEditorComponent implements EditorComponent<RootResource<CommunityProfile>>, OnInit, AfterViewInit, OnDestroy {
  private flatPickr!: Instance;
  private readonly feedService = inject(FeedService);
  private readonly collectionService = inject(CollectionService);
  private readonly userAccessApiService = inject(UserStateApiService);
  private readonly userApiService = inject(UserApiService);

  protected readonly Object = Object;
  protected readonly SubscriptionPlan = SubscriptionPlan;

  data!: ModalExchange<RootResource<CommunityProfile>, RootResource<CommunityProfile>>;

  userEmail!: string;
  firstName?: string;
  name?: string;
  userPlan!: SubscriptionPlan;
  subscriptionEndDate!: string;
  selectedElements: SelectableContentElement[] = [];
  contentElements$!: Observable<SelectableContentElement[]>;

  datePickerInput = viewChild<ElementRef>('datePickerInput');

  ngOnInit() {
    this.contentElements$ = combineLatest([
      this.feedService.load(this.data.input?.uuid!),
      this.collectionService.load(this.data.input?.uuid!)
    ])
      .pipe(
        map(([feeds, collections]) => this.mapToSelectableContentElements(feeds, collections))
      );
  }

  ngAfterViewInit() {
    this.flatPickr = flatpickr(this.datePickerInput()?.nativeElement, {
      dateFormat: 'd.m.Y',
      minDate: new Date()
    });
  }

  ngOnDestroy() {
    this.flatPickr.destroy();
  }

  getElementType(element: SelectableContentElement): string {
    if (element.type === ContentType.feed)
      return 'Feed';
    if (element.type === ContentType.collection)
      return 'Collection';

    return 'Other';
  }

  isSubmitDisabled(): boolean {
    return !ValidatorUtil.isEmail(this.userEmail) || !this.userPlan;
  }

  toggleSelection(element: SelectableContentElement) {
    element.isSelected = !element.isSelected;

    if (element.isSelected) {
      this.selectedElements.push(element);
    } else {
      this.selectedElements = this.selectedElements.filter(e => e.uuid !== element.uuid);
    }
  }

  submit() {
    const userInviteData: InviteUser = {
      firstName: this.firstName,
      name: this.name,
      email: this.userEmail,
      communityId: this.data.input!
    }
    this.userApiService.invite(userInviteData)
      .subscribe(() => {
        this.sendAssignSubscription();
        this.sendGrantAccessToSelectedElements();
        this.data.callback(this.data.input!);
      });
  }

  private sendAssignSubscription() {
    const subscription: SubscriptionRequest = {
      userEmail: this.userEmail,
      communityId: {
        creationTimestamp: this.data.input?.creationTimestamp!,
        uuid: this.data.input?.uuid!
      },
      subscriptionData: {
        plan: this.userPlan,
        expiryTimestamp: this.getExpirationDateTimestamp()
      }
    };

    this.userAccessApiService.assignSubscription(subscription).subscribe();
  }

  private sendGrantAccessToSelectedElements() {
    this.selectedElements.forEach(element => {
      const contentElement: ContentPurchase = {
        userEmail: this.userEmail,
        resourceId: {
          uuid: element.uuid,
          creationTimestamp: element.creationTimestamp,
          assignmentId: element.assignmentId
        },
        communityCreationTimestamp: this.data.input?.creationTimestamp!
      };

      if (element.type === ContentType.feed) {
        this.userAccessApiService.grantAccessToFeed(contentElement).subscribe();
      } else if (element.type === ContentType.collection) {
        this.userAccessApiService.grantAccessToCollection(contentElement).subscribe();
      }
    });
  }

  private mapToSelectableContentElements(feeds: Resource<Feed>[], collections: Resource<Collection>[]): SelectableContentElement[] {
    const contentElements: SelectableContentElement[] = [];

    feeds.forEach(feed => {
      contentElements.push({
        ...feed,
        type: ContentType.feed,
        isSelected: false
      });
    });

    collections.forEach(collection => {
      contentElements.push({
        ...collection,
        type: ContentType.collection,
        isSelected: false
      });
    })

    return contentElements;
  }

  private getExpirationDateTimestamp(): number | null {
    if (this.flatPickr.selectedDates?.length) {
      return this.flatPickr.selectedDates[0].getTime();
    }

    return null;
  }
}
