import {Component, HostBinding, Input} from '@angular/core';
import {NgIf, NgOptimizedImage} from "@angular/common";
import { RouterLink, RouterLinkActive } from "@angular/router";
import {CommunityProfile} from "../domain/community-profile";
import {RootResource} from "../domain/resource";
import {CommunityRoutes} from "../community.routes";
import {Subject} from "rxjs";
import {ProfileService} from "../profile/profile.service";
import { ScrollToTopButtonComponent } from '../../elements/scroll-to-top-button/scroll-to-top-button.component';
import {
  ShowScrollBarOnScrollDirective
} from '../../elements/show-scroll-bar-on-scroll/show-scroll-bar-on-scroll.directive';

@Component({
  selector: 'app-community',
  standalone: true,
  imports: [
    NgOptimizedImage,
    RouterLink,
    NgIf,
    ScrollToTopButtonComponent,
    RouterLinkActive,
    ShowScrollBarOnScrollDirective
  ],
  templateUrl: './community-navigation.component.html',
  styleUrl: './community-navigation.component.scss'
})
export class CommunityNavigationComponent {

  @HostBinding('class') class = 'd-flex w-100';
  community!: RootResource<CommunityProfile>;

  constructor(profileService: ProfileService) {
    profileService.activeCommunity().subscribe(c => this.community = c);
  }

  protected readonly AppRoutes = CommunityRoutes;
  protected readonly length = length;
}
