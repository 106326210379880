import {Component} from '@angular/core';
import {CommunityBaseComponent} from "../../community-base/community-base.component";
import { ActivatedRoute, Router, RouterLink, RouterLinkActive } from "@angular/router";
import {CommunityNavigationComponent} from "../../community-navigation/community-navigation.component";
import {FeedComponent} from "../view/feed.component";
import {Feed} from "../../domain/feed";
import {CommonModule} from "@angular/common";
import {Resource} from "../../domain/resource";
import {FeedService} from "../feed.service";
import {FeedInteractionService} from "../feed-interaction.service";
import {PostViewComponent} from "../../post/view/post-view.component";
import {Subscription, switchMap} from "rxjs";
import {CommunityRoutes} from "../../community.routes";
import {ProfileService} from "../../profile/profile.service";
import {ResourceAccessInteractionService} from "../../access-control/resource-access-interaction.service";
import {ResourceAccessService} from "../../access-control/resource-access.service";
import {HasWritePermissionDirective} from "../../access-control/has-write-permission.directive";
import {UserContentApiService} from '../../../api/user/user-content-api.service';

@Component({
  selector: 'app-feeds',
  standalone: true,
    imports: [
        CommonModule,
        CommunityNavigationComponent,
        FeedComponent,
        RouterLink,
        PostViewComponent,
        HasWritePermissionDirective,
        RouterLinkActive
    ],
  templateUrl: './feeds.component.html',
  styleUrl: './feeds.component.scss'
})
export class FeedsComponent extends CommunityBaseComponent {
  CommunityRoutes = CommunityRoutes;

  feeds!: Resource<Feed>[];
  loadSubscription!: Subscription;

  constructor(
    route: ActivatedRoute,
    profileService: ProfileService,
    private feedService: FeedService,
    private feedInteractionService: FeedInteractionService,
    // private profileService: ProfileService,
    // private postService: PostService
    private accessInteractionService: ResourceAccessInteractionService,
    accessService: ResourceAccessService,
    private router: Router,
    private userContentApiService: UserContentApiService
  ) {
    super(route, profileService, accessService);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.load();
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    if (this.loadSubscription)
      this.loadSubscription.unsubscribe();
  }

  onFeedClick(feed: Resource<Feed>) {
    this.userContentApiService.setUserOpenedFeed(feed).subscribe();
    void this.router.navigate([CommunityRoutes.feed(this.community.creationTimestamp, this.community.uuid, feed.creationTimestamp, feed.uuid)]);
  }

  create() {
    this.feedInteractionService.create(this.community.uuid, feedResource => {
      this.load();
      this.setAccess(feedResource);
    })
  }

  edit(feedResource: Resource<Feed>) {
    this.feedInteractionService.edit(feedResource, r => this.load());
  }

  setAccess(feedResource: Resource<Feed>) {
    this.accessInteractionService.setAccessLevel(feedResource, r => {
    }); //this.load());
  }

  load() {
    if (this.loadSubscription)
      this.loadSubscription.unsubscribe();
    // this.profileService.activeCommunity()
    this.loadSubscription = this.withCommunity()
      .pipe(switchMap(c => this.feedService.load(c.uuid)))
      .subscribe(feeds => this.feeds = feeds)
  }

}
