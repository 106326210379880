<app-community>
<!--  <div *ngIf="community">-->
    <li sidenav *ngFor="let collection of collections" class="nav-link">
      <a [href]="CommunityRoutes.collections(community?.creationTimestamp?? 0, community.uuid) +'#collection-'+ collection.uuid" class="nav-link" routerLinkActive="nav-link-active" aria-current="page">{{ collection.data.title }}</a>
    </li>
    <!-- Main Content -->
    <div main>
      <!--    todo: maybe remove:-->
      <h1 class="display-5 text-center pt-3 pb-5">Collections</h1>
      <div class="py-3"></div>
      <div class="p-5 d-flex justify-content-around">
        <button *hasWritePermission (click)="createCollection()" class="btn btn-primary rounded-circle">
          <i class="fa fa-plus"></i>
        </button>
      </div>
      <section class="section py-5" *ngFor="let collection of collections">
        <div *hasWritePermission class="d-flex justify-content-end">
          <button type="button" class="btn btn-secondary" (click)="setAccess(collection)">Access Setup</button>
          <button type="button" class="btn btn-primary" (click)="edit(collection)">Edit</button>
        </div>
        <div id="collection-{{collection.uuid}}" class="container py-5 post-container router-link" (click)="onCollectionClick(collection)">
          <div class="row align-items-center">
            <div class="col-lg-10 offset-lg-1">
              <div class="p-4">
                <h1 class="display-6">{{ collection.data.title }}</h1>
                <app-post-view *ngIf="collection.data.content" [post]="collection.data.content"></app-post-view>
                <hr class="my-5 mx-auto ruler">
                <div class="row">
                  <h2>Chapters</h2>
                  <div class="col-lg-12">
                    <div *ngFor="let element of collection.data.chapters">
                      <h4>{{ element.data.title }}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
<!--  </div>-->
</app-community>

<!--<li class="nav-item dropdown">-->
<!--  &lt;!&ndash;              todo: we toggle back here but actually should prevent toggling at all&ndash;&gt;-->
<!--  <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false" (click)="toggleCollapsed()">-->
<!--    Products-->
<!--  </a>-->
<!--  <ul class="dropdown-menu dropdown-menu-dark dropdown-menu-end" aria-labelledby="navbarDarkDropdownMenuLink">-->
<!--    <li>-->
<!--      <a class="dropdown-item" href="https://message-flow.keldysh.io" target="_blank">MessageFlow - Reactive Message Streams</a>-->
<!--    </li>-->
<!--    <li>-->
<!--      <a class="nav-link" style="color: #666666"><span>// </span>More coming soon. Stay tuned ;)</a>-->
<!--    </li>-->
<!--    &lt;!&ndash;                <li><a class="dropdown-item" href="#">Another action</a></li>&ndash;&gt;-->
<!--    &lt;!&ndash;                <li><a class="dropdown-item" href="#">Something else here</a></li>&ndash;&gt;-->
<!--  </ul>-->
<!--</li>-->
