import {Instant} from "./domain/instant";
import {Observable} from "rxjs";
import {Resource, ResourceId} from "./domain/resource";

export abstract class ResourceService<T> {

  abstract getSlice(instant: Instant, count: number, ascending: boolean): Observable<Resource<T>[]>;

  getAll(assignmentId: string, ascending: boolean): Observable<Resource<T>[]> {
    const start = ascending ? 0 : Number.MAX_SAFE_INTEGER;
    return this.getSlice({assignmentId: assignmentId, timestamp: start}, Number.MAX_SAFE_INTEGER, ascending)
  }

  abstract get(reference: ResourceId): Observable<Resource<T>>;

  abstract create(assignmentId: string, t: T): Observable<Resource<T>>;

  abstract save(resource: Resource<T>): Observable<Resource<T>>;

}
