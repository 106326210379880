import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ModalExchange} from "../../../elements/generic-modal/modal-exchange";
import {EditorComponent} from "../../editor-component";
import {Feed} from "../../domain/feed";
import {ContentElements} from "../../domain/content-element";
import {PostEditorComponent} from "../../post/editor/post-editor.component";
import {ResourceAccessService} from "../../access-control/resource-access.service";
import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'app-feed-editor',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    PostEditorComponent
  ],
  templateUrl: './feed-editor.component.html',
  styleUrl: './feed-editor.component.scss'
})
export class FeedEditorComponent implements EditorComponent<Feed>, OnInit {
  private postEditorHasChanges: boolean = false;

  @Input() data!: ModalExchange<Feed, Feed>;
  @ViewChild('postEditor') postEditor!: PostEditorComponent;

  feed!: Feed;

  constructor(
    protected resourceAccessService: ResourceAccessService,
    private notificationService: NotificationService
  ) {
  }

  ngOnInit(): void {
    if (this.data && this.data.input) {
      this.feed = Object.assign({}, this.data.input);
    } else {
      this.feed = ContentElements.empty()
    }
  }

  submit() {
    this.data.callback({title: this.feed.title, content: this.postEditor.generatePost()});
  }

  onPostEditorHasChanges(hasChanges: boolean) {
    this.postEditorHasChanges = hasChanges
  }

  beforeCancel(): boolean {
    if (this.hasChanges()) {
      return this.notificationService.showConfirmation();
    }
    return true;
  }

  private hasChanges(): boolean {
    return this.postEditorHasChanges || this.feed.title !== (this.data?.input?.title || '');
  }
}
