import { Component, inject, Input, OnInit } from '@angular/core';
import {NgIf, NgOptimizedImage} from "@angular/common";
import {MarkdownComponent} from "ngx-markdown";
import {ElementData} from "./elementData";
import { LazyLoadMediaDirective } from '../elements/lazy-load-video/lazy-load-media.directive';
import { MarkdownTag } from '../community/post/editor/markdown-tag';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-generic-element',
  standalone: true,
  imports: [
    NgIf,
    MarkdownComponent,
    NgOptimizedImage,
    LazyLoadMediaDirective
  ],
  templateUrl: './generic-element.component.html',
  styleUrl: './generic-element.component.scss'
})
export class GenericElementComponent implements OnInit{
  private readonly domSanitizer = inject(DomSanitizer);

  // Stored in a variable so that sanitation is done only once on init and not every time change detection kicks in which
  // would be the case when using a function or getter
  sanitizedYouTubeUrl: SafeResourceUrl | undefined;

  @Input() data!: ElementData;

  protected readonly MarkdownTag = MarkdownTag;

  ngOnInit() {
    if (this.data.kind === MarkdownTag.youTubeVideo) {
      this.sanitizedYouTubeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.data.content);
    }
  }
}
