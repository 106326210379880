<app-community>

  <!--  <div *ngIf="collection">-->
  <ng-container sidenav>
    <li class="nav-link">
      <a *ngIf="community"
         [routerLink]=collectionLink()
         routerLinkActive="nav-link-active"
         [routerLinkActiveOptions]="{exact: true}"
         class="nav-link"
         aria-current="page"
         (click)="onLinkClick($event)"
      >
        {{collection?.data?.title}}
      </a>
    </li>
    <li *ngFor="let chapter of collection?.data?.chapters; let index = index" class="nav-link ps-2">
      <a *ngIf="community"
         [routerLink]=chapterLink(chapter.uuid)
         routerLinkActive="nav-link-active"
         class="nav-link"
         aria-current="page"
         (click)="onLinkClick($event)"
      >
        {{ chapter.data.title }}

        @if (index === chapterIndex) {
          <ul class="nav navbar-nav nav-pills nav-headings">
            <li *ngFor="let header of chapterHeaders" class="nav-link" [ngClass]="'heading-level-' + header.level">
              <a class="nav-link py-1"
                 aria-current="page"
                 (click)="scrollToHeader($event, header)"
                 [innerHTML]="header.text"
              >
              </a>
            </li>
          </ul>
        }
      </a>
    </li>
  </ng-container>

  <!--  </div>-->

  <div main *ngIf="collection">
    @if (chapterIndex === -1) {
      <h1 class="display-6 text-center pt-3 pb-5">{{ collection?.data?.title }}</h1>
    }
    <div class="py-3"></div>
    @if (chapter) {
      <div>
        <div *hasWritePermission class="d-flex justify-content-end">
          <button type="button" class="btn btn-primary" (click)="editChapter(chapter)">Edit</button>
        </div>
        <h1 class="display-6">{{ chapter.title }}</h1>
        <app-post-view [post]="chapter.content?.data"></app-post-view>
      </div>
    }
    @if (chapterIndex === -1) {
      <div class="chapter-list-container text-center">
        @for (chapter of collection?.data?.chapters; track chapter.uuid) {
          <a *ngIf="community"
             [routerLink]=chapterLink(chapter.uuid)
             class="nav-link"
             aria-current="page"
             (click)="removeFocus($event)"
          >
            {{ chapter.data.title }}
          </a>
        }
      </div>
    }
    <div class="py-5 col-lg-4 offset-lg-4 d-flex justify-content-around">
      <!--      {{chapterIndex}}-->
      <button *ngIf="hasPreviousChapter(chapterIndex)" (click)="previousChapter()" class="btn btn-primary rounded-circle">
        <i class="fa fa-arrow-left"></i></button>
      <button *ngIf="hasNextChapter(chapterIndex)" (click)="nextChapter()" class="btn btn-primary rounded-circle">
        <i class="fa fa-arrow-right"></i></button>
      <ng-container *hasWritePermission>
        <button *ngIf="!hasNextChapter(chapterIndex)" (click)="addChapter()" class="btn btn-primary rounded-circle">
          <i class="fa fa-plus"></i></button>
      </ng-container>
    </div>
  </div>
</app-community>
