import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {CommunityBaseComponent} from "../../community-base/community-base.component";
import {ActivatedRoute} from "@angular/router";
import {CommunityNavigationComponent} from "../../community-navigation/community-navigation.component";
import {NgForOf, NgIf} from "@angular/common";
import {PostViewComponent} from "../../post/view/post-view.component";
import {Feed} from "../../domain/feed";
import {FeedComponent} from "../view/feed.component";
import {Resource} from "../../domain/resource";
import {FeedService} from "../feed.service";
import {ProfileService} from "../../profile/profile.service";
import {ResourceAccessService} from "../../access-control/resource-access.service";

@Component({
  selector: 'app-feed-standalone',
  standalone: true,
  imports: [
    CommunityNavigationComponent,
    NgForOf,
    PostViewComponent,
    FeedComponent,
    NgIf
  ],
  templateUrl: './feed-standalone.component.html',
  styleUrl: './feed-standalone.component.scss'
})
export class FeedStandaloneComponent extends CommunityBaseComponent implements OnInit {

  feed!: Resource<Feed>;

  constructor(
    private feedService: FeedService,
    route: ActivatedRoute,
    profileService: ProfileService,
    accessService: ResourceAccessService
  ) {
    super(route, profileService, accessService);
  }

  override ngOnInit() {
    super.ngOnInit();
    this.feedService.get(this.contentResourceId).subscribe(feed => {
      this.feed = feed;
    });
  }
}
