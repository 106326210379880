<ul class="heroes">
  <!-- todo: this should be in My Communities or similar-->
  <div class="p-5 d-flex justify-content-around">
    <button (click)="create()" class="btn btn-primary rounded-circle">
      <i class="fa fa-plus"></i>
    </button>
  </div>
  <li *ngFor="let community of communities">
    <a [routerLink]="AppRoutes.community(community.creationTimestamp, community.uuid)">
      <span>{{ community.data.title }}: </span> {{ community.data.shortDescription }}
    </a>
  </li>
</ul>
