<app-community>
  <div main>
    <div *hasWritePermission class="d-flex justify-content-end">
      <button type="button" class="btn btn-secondary me-1" (click)="onInviteCommunityCreatorButtonClick(community)">Invite Creator</button>
      <button type="button" class="btn btn-secondary me-1" (click)="onManageUserAccessButtonClick(community)">Manage User Access</button>
      <button type="button" class="btn btn-primary" (click)="edit(community)">Edit</button>
    </div>
    <h1 class="display-5 py-5 text-center">{{ community?.data?.title }}</h1>
    <!--    todo: maybe use something like a more button here-->
    <div *ngIf="community" class="on-page-introduction">
      <app-post-view [post]="community.data.content"></app-post-view>
    </div>
    <hr class="my-5 mx-auto ruler">
    <ng-container *ngIf="community">
      <!-- todo:  Maybe we should use this instead of delegat whatever things?!-->
      <app-feed [feedResource]="{uuid: community.uuid, creationTimestamp: community.creationTimestamp}"></app-feed>
    </ng-container>
  </div>
</app-community>
