import {Component} from '@angular/core';
import {CommunityNavigationComponent} from "../../community-navigation/community-navigation.component";
import {CommonModule, NgForOf} from "@angular/common";
import {PostViewComponent} from "../../post/view/post-view.component";
import { ActivatedRoute, Router, RouterLink, RouterLinkActive } from "@angular/router";
import {CommunityBaseComponent} from "../../community-base/community-base.component";
import {Resource} from "../../domain/resource";
import {Subscription, switchMap} from "rxjs";
import {Collection} from "../../domain/collection";
import {CollectionService} from "../collection.service";
import {CollectionInteractionService} from "../collection-interaction.service";
import {CommunityRoutes} from "../../community.routes";
import {ProfileService} from "../../profile/profile.service";
import {ResourceAccessInteractionService} from "../../access-control/resource-access-interaction.service";
import {Feed} from "../../domain/feed";
import {ResourceAccessService} from "../../access-control/resource-access.service";
import {HasWritePermissionDirective} from "../../access-control/has-write-permission.directive";
import {UserContentApiService} from '../../../api/user/user-content-api.service';

@Component({
  selector: 'app-collections',
  standalone: true,
    imports: [
        CommonModule,
        CommunityNavigationComponent,
        NgForOf,
        PostViewComponent,
        RouterLink,
        HasWritePermissionDirective,
        RouterLinkActive
    ],
  templateUrl: './collections.component.html',
  styleUrl: './collections.component.scss'
})
export class CollectionsComponent extends CommunityBaseComponent {

  CommunityRoutes = CommunityRoutes;
  collections!: Resource<Collection>[];

  loadSubscription!: Subscription;

  constructor(
    route: ActivatedRoute,
    profileService: ProfileService,
    private collectionService: CollectionService,
    private collectionInteractionService: CollectionInteractionService,
    private accessInteractionService: ResourceAccessInteractionService,
    accessService: ResourceAccessService,
    private router: Router,
    private userContentApiService: UserContentApiService
  ) {
    super(route, profileService, accessService);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.load();
  }

  onCollectionClick(collection: Resource<Collection>) {
    this.userContentApiService.setUserOpenedCollection(collection).subscribe();
    void this.router.navigate([CommunityRoutes.collection(this.community.creationTimestamp, this.community.uuid, collection.creationTimestamp, collection.uuid)]);
  }

  createCollection() {
    this.collectionInteractionService.create(this.community.uuid, collectionResource => {
      // firstValueFrom(this.postService.create(collectionResource.id, Posts.empty()))
      //   .then(r => this.loadCollections())
      this.load();
      this.setAccess(collectionResource)
    })
  }

  edit(collectionResource: Resource<Collection>) {
    this.collectionInteractionService.edit(collectionResource, r => this.load());
  }

  setAccess(feedResource: Resource<Feed>) {
    this.accessInteractionService.setAccessLevel(feedResource, r => {
    }); //this.load());
  }

  load() {
    if (this.loadSubscription)
      this.loadSubscription.unsubscribe();

    this.loadSubscription = this.withCommunity()
      // this.profileService.activeCommunity()
      .pipe(switchMap(c => this.collectionService.load(c.uuid)))
      .subscribe(collections => this.collections = collections);
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    if (this.loadSubscription)
      this.loadSubscription.unsubscribe();
  }
}
