<div *ngIf="data.kind === MarkdownTag.text">
  <p [innerHTML]="data.content"></p>
</div>
<div class="markdown-element" *ngIf="data.kind === MarkdownTag.markdown">
  <markdown [data]="data.content" [start]=""></markdown>
</div>
<div *ngIf="data.kind === MarkdownTag.video" class="video-margin py-3">
  <video height="auto" width="100%" preload="auto" controls controlsList="nodownload noplaybackrate"
         disablePictureInPicture [appLazyLoadMedia]="data.content"
         (click)="$event.stopPropagation()"
         (contextmenu)="$event.preventDefault()"
  >
    <!--  <video min-width="500" height="auto" controls controlslist="play timeline volume" >-->
    <source>
    Your browser does not support the video tag.
  </video>

  <!--  <app-video-js-player [options]="{-->
  <!--    fluid: true,-->
  <!--    aspectRatio: '16:9',-->
  <!--    autoplay: true,-->
  <!--    sources: [-->
  <!--      {-->
  <!--        src: data.content,-->
  <!--        type: 'video/mp4'-->
  <!--      }-->
  <!--    ]-->
  <!--  }"></app-video-js-player>-->

</div>
<div *ngIf="data.kind === MarkdownTag.image" class="video-margin py-3">
  <img [src]="data.content" height="auto" width="100%" loading="lazy" (contextmenu)="$event.preventDefault()">
</div>
<div *ngIf="data.kind === MarkdownTag.audio" class="video-margin py-3">
  <audio style="width: 100%" preload="metadata" controls controlsList="nodownload noplaybackrate"
         [appLazyLoadMedia]="data.content"
         (click)="$event.stopPropagation()"
         (contextmenu)="$event.preventDefault()"
  >
    <source>
    Your browser does not support the audio tag.
  </audio>
</div>
<div *ngIf="data.kind === MarkdownTag.downloadButton" class="py-3">
  <a class="btn btn-primary" role="button" [href]="data.content" download>Download</a>
</div>
<div *ngIf="data.kind === MarkdownTag.youTubeVideo" class="iframe-container">
  <iframe [src]="sanitizedYouTubeUrl"
          class="video-padding"
          title="YouTube video player" frameborder="0"
          allow="encrypted-media; picture-in-picture"
          referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
</div>
