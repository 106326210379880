<form>
  <div class="form-group">
    <label for="title">Title</label>
    <input id="title" class="form-control" [(ngModel)]="collection.title" name="title" required>
    <!--    <label for="kind">Kind</label>-->
    <!--    <input id="kind" class="form-control" [(ngModel)]="collection.kind" name="kind" required>-->
    <app-post-editor #postEditor [post]="collection.content"
                     [accessDelegate]="resourceAccessService.getCommunityDelegate.bind(resourceAccessService)"
                     (hasChanges)="onPostEditorHasChanges($event)"></app-post-editor>
    <!--    <label for="content">Introduction</label>-->
    <!--    <textarea id="content" class="form-control" [(ngModel)]="collection.introduction" name="content" rows="30" required></textarea>-->
  </div>
  <div class="d-flex justify-content-between pt-3">
    <button type="button" class="btn btn-primary" (click)="submit()">Save</button>
  </div>
</form>
