<div class="content d-flex w-100">
  <!-- Sidenav -->
  <nav *ngIf="community" class="sidenav d-flex flex-column p-md-3 p-2 navbar-dark col-2" style="background-color: var(--bs-primary); max-width: 14rem">
    <a [routerLink]="AppRoutes.community(community.creationTimestamp, community.uuid)" class="navbar-brand text-center logo-link">
      <img *ngIf="community?.data?.logoLink" ngSrc="{{community?.data?.logoLink}}" alt="{{ community?.data?.title}}" class="logo" height="120" width="500">
      <span style="font-weight: bold" *ngIf="!community?.data?.logoLink">{{ community?.data?.title}}</span>
    </a>
    <div class="flex-shrink-0 pt-3">
      <ul class="nav navbar-nav nav-pills p-lg-3 text-center">
<!--        &lt;!&ndash;       todo:  dedicated to intoduce community&ndash;&gt;-->
        <li class="nav-item">
          <a [routerLink]="AppRoutes.community(community.creationTimestamp, community.uuid)" routerLinkActive="nav-link-active" class="nav-link" aria-current="page">Home</a>
        </li>
<!--        &lt;!&ndash;      todo:  list  of products, potentially containing feeds and collections&ndash;&gt;-->
<!--        <li class="nav-item">-->
<!--          <a [routerLink]="AppRoutes.community(community.creationTimestamp, community.id)" class="nav-link" aria-current="page">Content</a>-->
<!--        </li>-->
        <!--        todo: maybe replace by products-->
        <li class="nav-item">
          <a [routerLink]="AppRoutes.feeds(community.creationTimestamp, community.uuid)" routerLinkActive="nav-link-active" class="nav-link" aria-current="page">Feeds</a>
        </li>
        <li class="nav-item">
          <a [routerLink]="AppRoutes.collections(community.creationTimestamp, community.uuid)" routerLinkActive="nav-link-active" class="nav-link" aria-current="page">Collections</a>
        </li>
      </ul>
      <hr style="color: var(--hover-color)">
    </div>
    <div class="flex-grow-1 overflow-y-auto">
      <ul class="nav navbar-nav nav-pills p-lg-3">
        <!--        <div class="overflow-y-auto" style="max-height: 65vh">-->
        <ng-content select="[sidenav]"></ng-content>
        <!--        </div>-->
      </ul>
    </div>

    <p class="mb-0 mt-2 flex-shrink-0">
      <span style="color: #818181">Powered by </span>
      <!--      <br>-->
      <a href="https://keldysh.io" target="_blank" style="color: var(--hover-color)">keldysh.io</a>
    </p>
    <!--  <div class="dropdown">-->
    <!--    <a href="#" class="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">-->
    <!--      <img src="https://github.com/mdo.png" alt="" width="32" height="32" class="rounded-circle me-2">-->
    <!--      <strong>mdo</strong>-->
    <!--    </a>-->
    <!--    <ul class="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownUser1">-->
    <!--      <li><a class="dropdown-item" href="#">New project...</a></li>-->
    <!--      <li><a class="dropdown-item" href="#">Settings</a></li>-->
    <!--      <li><a class="dropdown-item" href="#">Profile</a></li>-->
    <!--      <li>-->
    <!--        <hr class="dropdown-divider">-->
    <!--      </li>-->
    <!--      <li><a class="dropdown-item" href="#">Sign out</a></li>-->
    <!--    </ul>-->
    <!--  </div>-->
  </nav>

  <!-- Main Content -->
  <!--  works too -->
  <!--  <div id="communityMainContent" class="main-content flex-grow-1 overflow-y-auto">-->
  <div  #scrollToTop appShowScrollBarOnScroll id="communityMainContent" class="main-content col overflow-y-auto">
    <div class="container p-3">
      <ng-content select="[main]"></ng-content>
    </div>
  </div>
</div>
<app-scroll-to-top-button [scrollContainer]="scrollToTop"></app-scroll-to-top-button>
