import {Component, Input, ViewChild} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ModalExchange} from "../../../elements/generic-modal/modal-exchange";
import {EditorComponent} from "../../editor-component";
import {PostEditorComponent} from "../../post/editor/post-editor.component";
import {CommunityProfile, Profiles} from "../../domain/community-profile";
import {CommunityRoutes} from '../../community.routes';
import {NgIf, NgOptimizedImage} from '@angular/common';
import {ResourceAccessService} from "../../access-control/resource-access.service";
import { FileUploadService } from '../../post/file-upload.service';
import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'app-profile-editor',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    PostEditorComponent,
    NgOptimizedImage,
    NgIf
  ],
  templateUrl: './profile-editor.component.html',
  styleUrl: './profile-editor.component.scss'
})
export class ProfileEditorComponent implements EditorComponent<CommunityProfile> {
  private postEditorHasChanges: boolean = false;

  protected readonly AppRoutes = CommunityRoutes;

  @Input() data!: ModalExchange<CommunityProfile, CommunityProfile>;
  @ViewChild('postEditor') postEditor!: PostEditorComponent;

  profile!: CommunityProfile;

  constructor(
    private fileUploadService: FileUploadService,
    protected resourceAccessService: ResourceAccessService,
    private notificationService: NotificationService
  ) {
  }

  ngOnInit(): void {
    if (this.data && this.data.input) {
      this.profile = Object.assign({}, this.data.input);
    } else {
      this.profile = Profiles.initial();
    }
  }

  submit() {
    // this.profile.content = this.postEditor.generatePost();
    this.data.callback({
      title: this.profile.title,
      shortDescription: this.profile.shortDescription,
      content: this.postEditor.generatePost(),
      creatorIds: this.profile.creatorIds,
      logoLink: this.profile.logoLink
    });
  }

  addLogo(): void {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (event: any) => {
      if (event.target.files && event.target.files.length > 0) {
        const selectedFile = event.target.files[0];
        this.uploadLogo(selectedFile);
      }
    };
    input.click();
  }

  uploadLogo(file: File): void {
    this.fileUploadService.uploadFile(file).then((response) => {
      this.profile.logoLink = response.url;
      // const videoLink = `[video]: # (${response.url})`;
      // this.insertAtCursor(videoLink);
      // this.updatePreview();
    });
  }

  onPostEditorHasChanges(hasChanges: boolean) {
    this.postEditorHasChanges = hasChanges
  }

  beforeCancel(): boolean {
    if (this.hasChanges()) {
      return this.notificationService.showConfirmation();
    }
    return true;
  }

  private hasChanges(): boolean {
    return this.postEditorHasChanges
      || this.profile.title !== (this.data?.input?.title || '')
      || this.profile.logoLink !== (this.data?.input?.logoLink || '')
      || this.profile.shortDescription !== (this.data?.input?.shortDescription || '');
  }

}
