<div class="d-flex justify-content-end mt-2">
  <button type="button" class="btn btn-secondary me-1" #youTubeButton (click)="addYouTubeVideo()">Add YouTube Video
  </button>
  <button type="button" class="btn btn-secondary me-1" (click)="addDownloadButton()">Add PDF Download</button>
  <button type="button" class="btn btn-secondary me-1" (click)="addImage()">Add Image</button>
  <button type="button" class="btn btn-secondary me-1" (click)="addAudio()">Add Audio</button>
  <button type="button" class="btn btn-secondary" (click)="addVideo()">Add Video</button>
</div>
<div class="form-group">
  <textarea id="content" class="form-control" appShowScrollBarOnScroll [(ngModel)]="markdownContent"
            (ngModelChange)="onChange()" name="content" rows="30" required></textarea>
</div>
@if (showYouTubeUrlForm) {
  <app-youtube-url-form [referenceElement]="youTubeButton" (urlAdded)="onYouTubeUrlAdded($event)"
                        (closed)="onYouTubeUrlFormClosed()"></app-youtube-url-form>
}
