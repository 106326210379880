import {Injectable} from '@angular/core';
import {CommunityProfile} from "../domain/community-profile";
import {RootResourceService} from "../root-resource.service";
import {Observable, ReplaySubject, tap} from "rxjs";
import {RootResource} from "../domain/resource";

@Injectable({
  providedIn: 'root'
})
export abstract class ProfileService extends RootResourceService<CommunityProfile> {

  private selectedCommunity = new ReplaySubject<RootResource<CommunityProfile>>(1);

  protected constructor() {
    super()
  }

  selectCommunityProfile(creationTimestamp: number, communityId: string) {
    return this
      .get({creationTimestamp: creationTimestamp, uuid: communityId})
      .pipe(tap(community => {
        this.selectedCommunity.next(community);
      }));
  }

  activeCommunity() {
    return this.selectedCommunity.asObservable();
  }

  getCommunities(): Observable<RootResource<CommunityProfile>[]> {
    return this.getAll(true)
  };

  getCommunity(creationTimestamp: number, id: string): Observable<RootResource<CommunityProfile>> {
    return this.get({creationTimestamp: creationTimestamp, uuid: id});
  };
}
