import {Component, Input, OnInit} from '@angular/core';
import {AccessLevel} from "./resource-access.service";
import {EditorComponent} from "../editor-component";
import {ModalExchange} from "../../elements/generic-modal/modal-exchange";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {PostEditorComponent} from "../post/editor/post-editor.component";
import {CommonModule} from "@angular/common";

@Component({
  selector: 'app-access-control',
  standalone: true,
  imports: [
    FormsModule,
    PostEditorComponent,
    ReactiveFormsModule,
    CommonModule
  ],
  templateUrl: './access-control.component.html',
  styleUrl: './access-control.component.scss'
})
export class AccessControlComponent implements EditorComponent<AccessLevel>, OnInit {

  @Input() data!: ModalExchange<AccessLevel, AccessLevel>;

  constructor() {
  }

  accessLevel!: AccessLevel;

  ngOnInit(): void {
    if (this.data && this.data.input) {
      this.accessLevel = this.data.input;
    } else {
      this.accessLevel = AccessLevel.basicPlus;
    }
  }

  submit(){
    this.data.callback(this.accessLevel);
  }

  protected readonly Object = Object;
  protected readonly AccessLevels = AccessLevel;
}
