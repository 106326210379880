import {ContentElements} from "./content-element";
import {Feed} from "./feed";

export interface CommunityProfile extends Community, Feed {
  // feeds: NavigatableResource[]
  // collections: NavigatableResource[]
}

export interface Community {
  // id: string;
  title: string;
  creatorIds: string[]
  logoLink: string | null;
  shortDescription: string;
}

export class Profiles {
  static initial(): CommunityProfile {
    const element = ContentElements.empty();
    return {
      title: element.title,
      content: element.content,
      creatorIds: [],
      logoLink: "",
      shortDescription: "",
    }
  }
}
