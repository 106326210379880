import { ContentPurchase } from './domain/content-purchase';
import { Observable } from 'rxjs';
import { SubscriptionRequest } from './domain/subscription-request';
import { RootResourceId } from '../../community/domain/resource';

export abstract class UserStateApiService {
  abstract grantAccessToFeed(contentElement: ContentPurchase): Observable<void>;
  abstract grantAccessToCollection(contentElement: ContentPurchase): Observable<void>;
  abstract assignSubscription(subscription: SubscriptionRequest): Observable<void>;
  abstract addCreatorToCommunity(userEmail: string, communityId: RootResourceId): Observable<void>;
}
