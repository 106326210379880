<app-community>
  <li sidenav *ngFor="let feed of feeds" class="nav-link">
    <a [href]="CommunityRoutes.feeds(community.creationTimestamp, community.uuid) +'#feed-'+ feed.uuid" class="nav-link" routerLinkActive="nav-link-active" aria-current="page">{{ feed.data.title }}</a>
  </li>
  <!-- Main Content -->
  <div main>
    <h1 class="display-5 text-center pt-3 pb-5">Feeds</h1>
    <div class="py-3"></div>
    <div class="p-5 d-flex justify-content-around">
      <button *hasWritePermission (click)="create()" class="btn btn-primary rounded-circle">
        <i class="fa fa-plus"></i>
      </button>
    </div>
    <section class="section py-5" *ngFor="let feed of feeds">
      <div *hasWritePermission class="d-flex justify-content-end">
        <button type="button" class="btn btn-secondary" (click)="setAccess(feed)">Access Setup</button>
        <button type="button" class="btn btn-primary" (click)="edit(feed)">Edit</button>
      </div>
      <div id="feed-{{feed.uuid}}" class="container py-5 post-container router-link" (click)="onFeedClick(feed)">
        <div class="row align-items-center">
          <div class="col-lg-10 offset-lg-1">
            <div class="p-4">
              <h1 class="display-6">{{ feed.data.title }}</h1>
              <app-post-view *ngIf="feed.data.content" [post]="feed.data.content"></app-post-view>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</app-community>
