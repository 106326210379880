import { AfterViewInit, Component, ComponentRef, ElementRef, Input, ViewChild, ViewContainerRef } from '@angular/core';
import {NgClass, NgIf, NgStyle} from "@angular/common";
import {Modal} from "bootstrap";
import { EditorComponent } from '../../community/editor-component';

@Component({
  selector: 'app-modal',
  standalone: true,
  template: `
    <div class="modal fade" id="generic-modal" #genericModal tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
      <div class="modal-dialog" [ngClass]="dialogClasses">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalLabel">{{ title }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <ng-template #modalContent></ng-template>
          </div>
        </div>
      </div>
    </div>
  `,
  imports: [
    NgClass,
    NgIf,
    NgStyle
  ],
})
export class ModalComponent<T> implements AfterViewInit {
  private saveTriggered = false;
  private contentComponentRef!: ComponentRef<EditorComponent<T>>;

  @Input() title: string = 'Title';
  @Input() dialogClasses: string | string[] | Set<string> | { [klass: string]: any } = '';

  @ViewChild("genericModal")
  modalReference!: ElementRef;
  modal!: Modal;

  @ViewChild('modalContent', {read: ViewContainerRef}) modalContent!: ViewContainerRef;

  ngAfterViewInit() {
    // Listen for Bootstrap modal close event
    this.modalReference.nativeElement.addEventListener('hidden.bs.modal', () => {
      this.modalContent.clear();
      this.saveTriggered = false;
    });
    this.modalReference.nativeElement.addEventListener('hide.bs.modal', (event: Event) => {
      if (!this.saveTriggered && this.contentComponentRef.instance.beforeCancel) {
        const canClose = this.contentComponentRef.instance.beforeCancel();

        if (!canClose) {
          event.preventDefault();
        }
      }
    });

    this.modal = new Modal(this.modalReference.nativeElement);
  }

  open() {
    this.modal.show()
  }

  // close() {
  //   console.log("closing modal")
  //   this.closeAsync().then();
  //   // this.modal.hide();
  // }

  async closeBySave(): Promise<void> {
    this.saveTriggered = true;
    return new Promise<void>((resolve, reject) => {
      // Add an event listener for the `hidden.bs.modal` event
      this.modalReference.nativeElement.addEventListener('hidden.bs.modal', () => {
        // console.log("Modal hidden event fired");
        resolve();
      }, { once: true });  // `once: true` ensures the listener is removed after it's triggered

      // Hide the modal
      this.modal.hide();
    });
  }

  loadComponent(component: new (...args: any[]) => EditorComponent<T>, data?: Partial<T>) {
    this.contentComponentRef = this.modalContent.createComponent(component);

    if (data) {
      Object.assign(this.contentComponentRef.instance, data);
    }
  }
}
