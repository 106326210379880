import {Routes} from "@angular/router";
import {DiscoverComponent} from "./profile/discover/discover.component";
import {CollectionComponent} from "./collection/collection/collection.component";
import {ProfileComponent} from "./profile/view/profile.component";
import {FeedsComponent} from "./feed/feeds/feeds.component";
import {CollectionsComponent} from "./collection/collections/collections.component";
import {FeedStandaloneComponent} from "./feed/standalone/feed-standalone.component";

export const COMMUNITY_UUID = 'communityUuid';
export const COMMUNITY_CREATION_TIMESTAMP = 'communityCreationTimestamp';
export const CREATION_TIMESTAMP = 'creationTimestamp';
export const UUID = 'uuid';
export const CHAPTER_ID = 'elementId'

export const routes: Routes = [{
  path: '',
  children: [
    {path: 'profile', component: DiscoverComponent},
    {path: 'profile' + '/:' + COMMUNITY_CREATION_TIMESTAMP + '/:' + COMMUNITY_UUID, component: ProfileComponent},
    {path: 'feed' + '/:' + COMMUNITY_CREATION_TIMESTAMP + '/:' + COMMUNITY_UUID, component: FeedsComponent},
    {
      path: 'feed' + '/:' + COMMUNITY_CREATION_TIMESTAMP + '/:' + COMMUNITY_UUID + '/:' + CREATION_TIMESTAMP + '/:' + UUID,
      component: FeedStandaloneComponent
    },
    {path: 'collection' + '/:' + COMMUNITY_CREATION_TIMESTAMP + '/:' + COMMUNITY_UUID, component: CollectionsComponent},
    {
      path: 'collection' + '/:' + COMMUNITY_CREATION_TIMESTAMP + '/:' + COMMUNITY_UUID + '/:' + CREATION_TIMESTAMP + '/:' + UUID,
      component: CollectionComponent
    },
    {
      path: 'collection' + '/:' + COMMUNITY_CREATION_TIMESTAMP + '/:' + COMMUNITY_UUID + '/:' + CREATION_TIMESTAMP + '/:' + UUID + '/:' + CHAPTER_ID,
      component: CollectionComponent
    },
  ]
}];

export class CommunityRoutes {

  static communities() {
    return '/community/profile'
  }

  static community(creationTimestamp: number, id: string) {
    return '/community/profile' + '/' + creationTimestamp + '/' + id;
  }

  static feeds(communityCreationTimestamp: number, communityId: string) {
    return '/community/feed' + '/' + communityCreationTimestamp + '/' + communityId;
  }

  static feed(communityCreationTimestamp: number, communityId: string, creationTimestamp: number, id: string) {
    return '/community/feed' + '/' + communityCreationTimestamp + '/' + communityId + '/' + creationTimestamp + '/' + id;
  }

  static collections(communityCreationTimestamp: number, communityId: string) {
    return '/community/collection' + '/' + communityCreationTimestamp + '/' + communityId;
  }

  static collection(communityCreationTimestamp: number, communityId: string, creationTimestamp: number, id: string) {
    return '/community/collection' + '/' + communityCreationTimestamp + '/' + communityId + '/' + creationTimestamp + '/' + id;
  }

  static chapter(communityCreationTimestamp: number, communityId: string, creationTimestamp: number, id: string, chapterId: string) {
    return '/community/collection' + '/' + communityCreationTimestamp + '/' + communityId + '/' + creationTimestamp + '/' + id + '/' + chapterId;
  }
}
